import { POSTS_PER_PAGE } from '../constants/pagination';
import fetchMatcherPosts from './fetch-updated-posts';
import { actionPromiseCache } from '../services/action-promise-cache';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { markPostsAsSeen } from '../containers/user-activity';

export const fetchUpdatedCategoryPosts = actionPromiseCache(
  ({ siteMemberId, categoryId, page, sort, postType, pageSize = POSTS_PER_PAGE }) => (
    dispatch,
    getState,
  ) => {
    const state = getState();
    const isRecentActivityCatsEnabled = isExperimentEnabled(state, EXPERIMENT_RECENT_ACTIVITY_CATS);

    const promise = dispatch(
      fetchMatcherPosts({ siteMemberId, categoryId, page, pageSize, sort, postType }),
    );

    if (!isSSR(state) && isRecentActivityCatsEnabled) {
      promise.then(response => {
        if (response.body.length > 0) {
          dispatch(markPostsAsSeen(response.body.map(p => p._id)));
        }
      });
    }

    return promise;
  },
  {
    cacheKeyBuilder: args => `${args.categoryId}${args.page}${args.sort}${args.siteMemberId}`,
    cacheTime: 1000,
    ignoreResolve: true,
  },
);
